<template>
    <main class="swcluster-main" id="swcluster-datasandbox-awsglue">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <CommonBreadcrumb :show-title="true" />
                <div class="header-links">
                    <a href="javascript:" class="link text-muted" @click="goAws('glue')">Glue Studio <i class="icon-link"></i></a>
                </div>
            </div>
        </div>
        <!-- page-body -->
        <div class="page-body page-component">
            <!-- page-aside -->
            <SandboxSideBar />
            <!-- page-contents -->
            <div class="page-contents">
                <!-- content-section:Crawler -->
                <article class="content-section">
                    <header class="section-header header-divider">
                        <h3 class="title title-sm">Crawler</h3>
                    </header>
                    <div class="table-container">
                        <div class="table table-bordered">
                            <table>
                                <colgroup>
                                    <col style="width: 35%" />
                                    <col style="width: auto" />
                                    <col style="width: auto" />
                                    <col style="width: auto" />
                                    <col style="width: auto" />
                                    <col style="width: auto" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="table-cell"><span class="cell-title">이름</span></div>
                                        </th>
                                        <th>
                                            <div class="table-cell"><span class="cell-title">마지막런타임</span></div>
                                        </th>
                                        <th>
                                            <div class="table-cell"><span class="cell-title">중간런타임</span></div>
                                        </th>
                                        <th>
                                            <div class="table-cell"><span class="cell-title">테이블업데이트</span></div>
                                        </th>
                                        <th>
                                            <div class="table-cell"><span class="cell-title">테이블추가</span></div>
                                        </th>
                                        <th>
                                            <div class="table-cell"><span class="cell-title">상태</span></div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="renderCrawlerItems.length === 0">
                                        <td colspan="6" style="text-align: center">
                                            <div class="table-cell">-</div>
                                        </td>
                                    </tr>

                                    <tr v-for="(item, index) in renderCrawlerItems" :key="index">
                                        <td>
                                            <div class="table-cell">
                                                <router-link :to="{name: 'GlueCrawler', params: {crawlerName: item.name}}" class="cell-text text-blue">{{
                                                    item.name
                                                }}</router-link>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-cell">
                                                <span class="cell-text"
                                                    >{{ item.glueCrawlerMetrics ? item.glueCrawlerMetrics.lastRuntimeSeconds.toFixed(0) : 0 }}초</span
                                                >
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-cell">
                                                <span class="cell-text"
                                                    >{{ item.glueCrawlerMetrics ? item.glueCrawlerMetrics.medianRuntimeSeconds.toFixed(0) : 0 }}초</span
                                                >
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-cell">
                                                <span class="cell-text">{{ item.glueCrawlerMetrics ? item.glueCrawlerMetrics.tablesUpdated : 0 }}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-cell">
                                                <span class="cell-text">{{ item.glueCrawlerMetrics ? item.glueCrawlerMetrics.tablesCreated : 0 }}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-cell">
                                                <strong
                                                    class="cell-text"
                                                    :class="{'text-green': item.state === 'READY', 'text-primary': item.state === 'SUCCEEDED'}"
                                                    >{{ item.state }}</strong
                                                >
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- pagination -->
                        <CommonPagination :paging="crawlerPaging" :page-func="crawlerPageFunc" />
                    </div>
                </article>
                <!-- content-section:Job -->
                <article class="content-section section-margin">
                    <header class="section-header header-divider">
                        <h3 class="title title-sm">Job</h3>
                    </header>
                    <div class="table-container">
                        <div class="table table-bordered">
                            <table>
                                <colgroup>
                                    <col style="width: 35%" />
                                    <col style="width: auto" />
                                    <col style="width: auto" />
                                    <col style="width: auto" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="table-cell"><span class="cell-title">이름</span></div>
                                        </th>
                                        <th>
                                            <div class="table-cell"><span class="cell-title">타입</span></div>
                                        </th>
                                        <th>
                                            <div class="table-cell"><span class="cell-title">수정시간</span></div>
                                        </th>
                                        <th>
                                            <div class="table-cell"><span class="cell-title">AWS Glue 버전</span></div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="renderJobItems.length === 0">
                                        <td colspan="4" style="text-align: center">
                                            <div class="table-cell">-</div>
                                        </td>
                                    </tr>
                                    <tr v-for="(item, index) in renderJobItems" :key="index">
                                        <td>
                                            <div class="table-cell">
                                                <router-link
                                                    :to="{name: 'GlueJob', params: {jobName: item.name}}"
                                                    href="javascript:"
                                                    class="cell-text text-blue"
                                                    >{{ item.name }}</router-link
                                                >
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-cell">
                                                <span class="cell-text">{{ item.cmdName }}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-cell">
                                                <span class="cell-text">{{ timestampToDateFormat(item.lastModifyTm, 'yyyy.MM.dd hh:mm') }}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-cell">
                                                <span class="cell-text">{{ item.version ? item.version : '-' }}</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- pagination -->
                        <CommonPagination :paging="jobPaging" :page-func="jobPageFunc" />
                    </div>
                </article>
            </div>
            <!-- //page-contents -->
        </div>
        <!-- //page-body -->
    </main>
</template>

<script>
import {computed, reactive, ref} from 'vue';
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';
import {getItems, lengthCheck, paginate, timestampToDateFormat} from '@/assets/js/common.utils';
import SandboxSideBar from '@/components/sandbox/SandboxSideBar';
import CommonPagination from '@/components/common/CommonPagination';
import {goAws} from '@/assets/js/modules/aws/module';
import ApiService from '@/assets/js/api.service';

export default {
    name: 'Glue',
    components: {CommonPagination, SandboxSideBar, CommonBreadcrumb},
    setup: function () {
        const crawlerPaging = reactive({
            pageNo: 1,
            pageSize: 10,
            totalCount: computed(() => crawlerItems.value.length),
        });

        const jobPaging = reactive({
            pageNo: 1,
            pageSize: 10,
            totalCount: computed(() => jobItems.value.length),
        });

        const crawlerItems = ref([]);
        const jobItems = ref([]);

        const renderCrawlerItems = computed(() => paginate(crawlerItems.value, crawlerPaging.pageNo, crawlerPaging.pageSize));
        const renderJobItems = computed(() => paginate(jobItems.value, jobPaging.pageNo, jobPaging.pageSize));

        const getGlueCrawlerList = () => {
            ApiService.query('/v1/aws/glue/crawlers', {}).then(res => {
                if (lengthCheck(res)) {
                    crawlerItems.value = getItems(res);
                }
            });
        };

        const getGlueJobList = () => {
            ApiService.query('/v1/aws/glue/jobs', {}).then(res => {
                if (lengthCheck(res)) {
                    jobItems.value = getItems(res);
                }
            });
        };

        const crawlerPageFunc = pageNo => {
            crawlerPaging.pageNo = pageNo;
        };

        const jobPageFunc = pageNo => {
            jobPaging.pageNo = pageNo;
        };

        getGlueCrawlerList();
        getGlueJobList();

        return {
            renderCrawlerItems,
            renderJobItems,
            crawlerPaging,
            jobPaging,

            crawlerPageFunc,
            jobPageFunc,
            goAws,
            timestampToDateFormat,
        };
    },
};
</script>
